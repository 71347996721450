









































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import { State, Getter } from "vuex-class";
import { Contracts, AssetSymbol } from "@/utils/types";
import { ethers, Signer } from "ethers";
import IERC20ABI from "@/contracts/IERC20.json";
import { IERC20 } from "@/contracts/IERC20";
import MockERC20ABI from "@/contracts/MockERC20.json";
import FaucetABI from "@/contracts/Faucet.json";
import { decimalToTokenBaseUnits } from "@/utils/tokens";
import { allAssets, reserveUnderlyingAssets } from "@/config/assets";
import { IBaseOracle } from "@/contracts/IBaseOracle";
import MockPriceOracleABI from "@/contracts/MockPriceOracle.json";
import { MockPriceOracle } from "@/contracts/MockPriceOracle";
import { tokenDecimals } from "@/utils/constants";

@Component({
  components: { PageHeader }
})
export default class Admin extends Vue {
  @State contracts!: Contracts;
  @State signer!: Signer;
  @State provider!: ethers.providers.JsonRpcProvider;
  @State tokenBalances!: { [key: string]: number };
  @State prices!: { [key: string]: string };

  @Getter isConnected!: boolean;

  private assets = allAssets;
  private cachedFaucetBalances = {
    DAI: "0",
    USDT: "0",
    USDC: "0",
    "3CRV": "0"
  };

  private mintForm: {
    token: AssetSymbol;
    amount: number | undefined;
  } = {
    token: "DAI",
    amount: undefined
  };

  private priceUpdates: { [key: string]: string } = {};

  private readonly tokens = ["DAI", "USDC", "USDT", "3CRV"];
  $toasted: any;

  get updatePriceForm(): { [key: string]: string } {
    const prices: { [key: string]: string } = {};

    for (const token of this.tokens) {
      prices[token] =
        this.priceUpdates[token] ??
        this.trimTrailingDecimalZeros((this.prices ?? {})[token] ?? "0");
    }

    return prices;
  }

  set updatePriceForm(val) {
    for (const asset in val) {
      if (Object.prototype.hasOwnProperty.call(val, asset)) {
        const price = val[asset];
        if (price !== this.updatePriceForm[price]) {
          this.$set(this.priceUpdates, asset, price);
        }
      }
    }
  }

  faucetBalances(tokenSymbol: AssetSymbol): string {
    this.updateFaucetBalanceOf(tokenSymbol);

    return this.cachedFaucetBalances[tokenSymbol];
  }

  async updateFaucetBalanceOf(tokenSymbol: AssetSymbol) {
    const token = new ethers.Contract(
      this.contracts[tokenSymbol],
      IERC20ABI,
      this.provider
    ) as IERC20;

    const balance = await token.balanceOf(this.contracts.Faucet);

    this.cachedFaucetBalances[tokenSymbol] = ethers.utils.formatUnits(
      balance,
      tokenDecimals[tokenSymbol]
    );
  }

  trimTrailingDecimalZeros(price: string): string {
    // Remove trailing decimal 0s from price
    const split = price.split(".");
    if (split[1] === undefined) {
      return price;
    }

    let i;
    for (i = 0; i <= split[1].length; i++) {
      const digit = split[1][split[1].length - 1 - i];

      if (digit !== "0") {
        break;
      }
    }

    return split[0] + "." + split[1].substr(0, split[1].length - i);
  }

  // @Watch("prices")
  // priceChange(newPrices: { [key: string]: string }) {
  //   for (const token in newPrices) {
  //     if (Object.prototype.hasOwnProperty.call(newPrices, token)) {
  //       const price = newPrices[token];

  //       this.updatePriceForm[token] = price;
  //     }
  //   }
  // }

  private advanceBlockTimeForm = {
    amount: 10
  };

  private balanceFor(token: string): number {
    return this.tokenBalances[token] ?? 0;
  }

  private async mintTokens() {
    try {
      const token = this.mintForm.token;

      const faucetAddress = this.contracts.Faucet;
      const faucetContract = new ethers.Contract(
        faucetAddress,
        FaucetABI,
        this.signer
      );

      const amount = this.mintForm.amount ?? 0;
      if (amount <= 0) {
        return;
      }

      const baseUnitAmount = decimalToTokenBaseUnits(token, amount);
      const tokenAddress = this.contracts[token];
      const tx = await faucetContract.withdraw(tokenAddress, baseUnitAmount);
      await tx.wait(1);
      this.$toasted
        .success(`Recevied ${amount} ${token} from faucet`)
        .goAway(1000);
    } catch (e) {
      this.$toasted
        .error("Failed to get balance from faucet... Check logs")
        .goAway(2000);
      console.error(e);
    }
  }

  private async advanceBlockTime() {
    const secondsToIncrease = this.advanceBlockTimeForm.amount;
    await this.provider.send("evm_increaseTime", [secondsToIncrease]);
    await this.provider.send("evm_mine", []);
  }

  private async updatePrices() {
    const oracle = new ethers.Contract(
      this.contracts.PriceOracle,
      MockPriceOracleABI,
      this.signer
    ) as MockPriceOracle;

    const weiPerEth = ethers.BigNumber.from(10).pow(18);
    const ray = ethers.BigNumber.from(10).pow(27);

    for (const asset in this.priceUpdates) {
      if (Object.prototype.hasOwnProperty.call(this.priceUpdates, asset)) {
        const price = this.priceUpdates[asset];

        const assetAddress = (this.contracts as any)[asset];

        const split = price.split(".");

        const unitsPerToken = ethers.BigNumber.from(10).pow(
          (tokenDecimals as any)[asset]
        );

        let bnPrice = ethers.BigNumber.from(split[0])
          .mul(weiPerEth)
          .mul(ray);

        if (split[1] !== undefined) {
          const multiplier = ethers.BigNumber.from(10).pow(
            18 + 27 - split[1].length
          );
          const bnDecimals = ethers.BigNumber.from(split[1]).mul(multiplier);
          bnPrice = bnPrice.add(bnDecimals);
        }

        bnPrice = bnPrice.div(unitsPerToken);

        const tx = await oracle.setWeiPricePerUnitInRay(assetAddress, bnPrice);
        await tx.wait(1);
        this.$toasted
          .success(`Set ${asset} price to ${price} ETH`)
          .goAway(2000);
      }
    }
  }
}
